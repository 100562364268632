/**
 *  Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

"use strict";

var formValidation = require("./egFormValidation");

/**
 * @param {Object} $elementAppendTo - The element to append error html to
 * @param {string} msg - The error message
 * display error message if remove gift registry failed
 */
function displayErrorMessage($elementAppendTo, msg) {
    if ($(".remove-gift-registry-error-messages").length === 0) {
        $elementAppendTo.append(
            "<div class=\"remove-gift-registry-error-messages \"></div>"
        );
    }
    $(".remove-gift-registry-error-messages").append(
        "<div class=\"remove-gift-registry-error-alert text-center alert-danger\">"
            + msg
            + "</div>"
    );

    setTimeout(function () {
        $(".remove-gift-registry-error-messages").remove();
    }, 3000);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = "<div class=\"alert alert-danger alert-dismissible valid-cart-error "
    + "fade show\" role=\"alert\">"
    + "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">"
    + "<span aria-hidden=\"true\">&times;</span>"
    + "</button>" + message + "</div>";
    $(".cart-error").append(errorHtml);
}

module.exports = function () {
    $("body").off("click", ".remove-item-from-gift-registry");
    $("body").on(
        "click",
        ".remove-item-from-gift-registry",
        function (e) {
            e.preventDefault();

            $(".gr-item-cards").spinner().start();
            var url = $(this).data("url");

            $.ajax({
                url: url,
                type: "get",
                dataType: "json",
                data: {},
                success: function (data) {
                    $(".uuid-" + data.UUID).remove();
                    if (data.listIsEmpty && $("#add-items").length === 0) {
                        var html = "<div id =\"add-items\" class=\"row justify-content-center\">"
                        + "<a href=" + data.addNewItemURL + ">"
                        + data.addNewItemText
                        + "</a></div>";
                        $("#eventInfo").append(html);
                        $(".clear-list-btn").addClass("d-none");
                    }

                    if (data.renderHTML) {
                        $(".gr-item-cards .card")
                            .add(".more-gr-list-items")
                            .remove();
                        $(".gr-item-cards").append(data.renderHTML);
                    }

                    if ($(".registry-gift-certificate").length === 0) {
                        $(".add-giftcert-to-giftregistry").removeClass(
                            "d-none"
                        );
                    }

                    $(".gr-item-cards").spinner().stop();
                },
                error: function (error) {
                    if (error.responseJSON.redirectUrl) {
                        window.location.href = error.responseJSON.redirectUrl;
                    } else {
                        var $elToAppend = $(".gr-item-cards");
                        $elToAppend.spinner().stop();
                        var msg = $elToAppend.data("error-msg");
                        displayErrorMessage($elToAppend, msg);
                    }
                }
            });
        }
    );

    $("body").off("click", ".gift-registry-clear-list-confirmation-btn");
    $("body").on(
        "click",
        ".gift-registry-clear-list-confirmation-btn",
        function (e) {
            e.preventDefault();
            var $elToAppend = $(".gr-item-cards");
            $elToAppend.spinner().start();
            var url = $(this).data("action");

            $.ajax({
                url: url,
                type: "get",
                dataType: "json",
                success: function (data) {
                    if (data.listIsEmpty) {
                        $elToAppend.empty();
                        $(".add-items-to-gift-registry").removeClass("d-none");
                        $(".clear-list-btn").addClass("d-none");
                        $(".add-giftcert-to-giftregistry").removeClass(
                            "d-none"
                        );
                        $elToAppend.spinner().stop();
                        $(".modal-backdrop").remove();
                        $("body")
                            .removeClass("modal-open")
                            .css("padding-right", "0px");
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $elToAppend.spinner().stop();
                    }
                }
            });
        }
    );

    $("body").off("click", "#registryInfo .card button.edit");
    $("body").on("click", "#registryInfo .card button.edit", function () {
        $(this).parents("form");
        var $form = $(this).parents(".card-body").find("form");
        var formData = $form.serialize();
        var url = $("#registryInfo").data("edit-url");
        var formToReset = $form.find(".eventFormType").val();
        var registryID = $("#registryInfo").data("id");
        var $eventSummary = $(this)
            .parents(".card-body")
            .find(".event-summary");
        var $editForm = $(this).parents(".card-body").find(".edit-form");
        var $editLink = $(this)
            .parents(".card")
            .find(".card-header .edit-button");
        formData = formData + "&registryID=" + registryID;
        var addressID;
        var addressUUID;
        if (formToReset === "preEvent" || formToReset === "postEvent") {
            addressID = $editForm.find(".grAddressSelector").val();
            addressUUID = $editForm
                .find(".grAddressSelector")
                .find(":selected")
                .data("uuid");
            formData = formData + "&addressID="
            + addressID
            + "&addressUUID="
            + addressUUID;
        }
        $.spinner().start();
        $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            data: formData,
            success: function (response) {
                if (Object.keys(response.fields).length > 0) {
                    formValidation($form, response);
                } else {
                    $editForm.find(".invalid-feedback").html("");
                    $editForm.find(".is-invalid").removeClass("is-invalid");
                    $eventSummary.removeClass("d-none");
                    $editForm.addClass("d-none");
                    $editLink.removeClass("d-none");
                    switch (formToReset) {
                        case "event":
                            $(".event-name-summary").html(
                                response.data.eventName
                            );
                            $(".event-name").html(response.data.eventName);
                            $(".event-country-summary").html(
                                response.data.eventCountry
                            );
                            $(".event-date-summary").html(
                                response.data.eventDate
                            );
                            $(".event-state-summary").html(
                                response.data.eventState
                            );
                            $(".event-city-summary").html(
                                response.data.eventCity
                            );
                            break;
                        case "registrant":
                            $(
                                ".registrant-summary .registrant-role-summary"
                            ).html(response.data.role);
                            $(
                                ".registrant-summary .registrant-first-name-summary"
                            ).html(response.data.firstName);
                            $(
                                ".registrant-summary .registrant-last-name-summary"
                            ).html(response.data.lastName);
                            $(
                                ".registrant-summary .registrant-email-summary"
                            ).html(response.data.email);
                            break;
                        case "coRegistrant":
                            $(
                                ".co-registrant-summary .registrant-role-summary"
                            ).html(response.data.role);
                            $(
                                ".co-registrant-summary .registrant-first-name-summary"
                            ).html(response.data.firstName);
                            $(
                                ".co-registrant-summary .registrant-last-name-summary"
                            ).html(response.data.lastName);
                            $(
                                ".co-registrant-summary .registrant-email-summary"
                            ).html(response.data.email);
                            break;
                        case "preEvent":
                            $(
                                ".pre-event-shipping-summary .address-tittle-summary"
                            ).html(response.data.id);
                            $(".pre-event-shipping-summary .fname").html(
                                response.data.firstName
                            );
                            $(".pre-event-shipping-summary .lname").html(
                                response.data.lastName
                            );
                            $(
                                ".pre-event-shipping-summary .summary-addr-1"
                            ).html(response.data.address1);
                            $(
                                ".pre-event-shipping-summary .summary-addr-2"
                            ).html(response.data.address2);
                            $(".pre-event-shipping-summary .city").html(
                                response.data.city
                            );
                            $(".pre-event-shipping-summary .zip").html(
                                response.data.postalCode
                            );
                            $(".pre-event-shipping-summary .state").html(
                                response.data.stateCode
                            );
                            $(".pre-event-shipping-summary .phone").html(
                                response.data.phone
                            );
                            $(
                                ".pre-event-shipping-summary .address-tittle-summary"
                            ).html(response.data.id);
                            break;
                        case "postEvent":
                            $(
                                ".post-event-shipping-summary .address-tittle-summary"
                            ).html(response.data.id);
                            $(".post-event-shipping-summary .fname").html(
                                response.data.firstName
                            );
                            $(".post-event-shipping-summary .lname").html(
                                response.data.lastName
                            );
                            $(
                                ".post-event-shipping-summary .summary-addr-1"
                            ).html(response.data.address1);
                            $(
                                ".post-event-shipping-summary .summary-addr-2"
                            ).html(response.data.address2);
                            $(".post-event-shipping-summary .city").html(
                                response.data.city
                            );
                            $(".post-event-shipping-summary .zip").html(
                                response.data.postalCode
                            );
                            $(".post-event-shipping-summary .state").html(
                                response.data.stateCode
                            );
                            $(".post-event-shipping-summary .phone").html(
                                response.data.phone
                            );
                            $(
                                ".post-event-shipping-summary .address-tittle-summary"
                            ).html(response.data.id);
                            break;
                        // no default
                    }
                }

                if (formToReset === "postEvent" || formToReset === "preEvent") {
                    $(".grAddressSelector option").each(function () {
                        if ($(this).data("uuid") === response.addressUUID) {
                            if ($(this).is(":selected")) {
                                var $thisForm = $(this).parents("form");
                                $thisForm
                                    .find(".addressID")
                                    .val(
                                        response.editedAddress.address.addressId
                                    );
                                $thisForm
                                    .find(".firstName")
                                    .val(
                                        response.editedAddress.address.firstName
                                    );
                                $thisForm
                                    .find(".lastName")
                                    .val(
                                        response.editedAddress.address.lastName
                                    );
                                $thisForm
                                    .find(".address1")
                                    .val(
                                        response.editedAddress.address.address1
                                    );
                                $thisForm
                                    .find(".address2")
                                    .val(
                                        response.editedAddress.address.address2
                                    );
                                $thisForm
                                    .find(".city_address")
                                    .val(response.editedAddress.address.city);
                                $thisForm
                                    .find(".postalCode")
                                    .val(
                                        response.editedAddress.address
                                            .postalCode
                                    );
                                $thisForm
                                    .find(".phone_address")
                                    .val(response.editedAddress.address.phone);
                                $thisForm
                                    .find(".stateCode")
                                    .val(
                                        response.editedAddress.address.stateCode
                                    );
                                $thisForm
                                    .find(".country")
                                    .val(
                                        response.editedAddress.address
                                            .countryCode.value
                                    );
                                $thisForm
                                    .find(".is-invalid")
                                    .removeClass("is-invalid");
                                $thisForm.find(".invalid-feedback").html("");

                                var $thisSummary = $thisForm
                                    .parents(".card")
                                    .find(".event-summary");
                                $thisSummary
                                    .find(".address-tittle-summary")
                                    .html(response.editedAddress.address.ID);
                                $thisSummary
                                    .find(".fname")
                                    .html(
                                        response.editedAddress.address.firstName
                                    );
                                $thisSummary
                                    .find(".lname")
                                    .html(
                                        response.editedAddress.address.lastName
                                    );
                                $thisSummary
                                    .find(".summary-addr-1")
                                    .html(
                                        response.editedAddress.address.address1
                                    );
                                $thisSummary
                                    .find(".summary-addr-2")
                                    .html(
                                        response.editedAddress.address.address2
                                    );
                                $thisSummary
                                    .find(".state")
                                    .html(
                                        response.editedAddress.address.stateCode
                                    );
                                $thisSummary
                                    .find(".city")
                                    .html(response.editedAddress.address.city);
                                $thisSummary
                                    .find(".zip")
                                    .html(
                                        response.editedAddress.address
                                            .postalCode
                                    );
                                $thisSummary
                                    .find(".phone")
                                    .html(response.editedAddress.address.phone);
                            }

                            $(this).data(
                                "address-title",
                                response.editedAddress.address.ID
                            );
                            $(this).data(
                                "first-name",
                                response.editedAddress.address.firstName
                            );
                            $(this).data(
                                "last-name",
                                response.editedAddress.address.lastName
                            );
                            $(this).data(
                                "address1",
                                response.editedAddress.address.address1
                            );
                            $(this).data(
                                "address2",
                                response.editedAddress.address.address2
                            );
                            $(this).data(
                                "city",
                                response.editedAddress.address.city
                            );
                            $(this).data(
                                "postal-code",
                                response.editedAddress.address.postalCode
                            );
                            $(this).data(
                                "phone",
                                response.editedAddress.address.phone
                            );
                            $(this).data(
                                "state-code",
                                response.editedAddress.address.stateCode
                            );
                            $(this).data(
                                "country-code",
                                response.editedAddress.address.countryCode.value
                            );
                            $(this).val(response.editedAddress.address.ID);
                            $(this).html(response.editedAddressLabel);
                        }
                    });
                }
                if (response.newAddress) {
                    var uuidToRemove = response.editedAddress.UUID;
                    $(".grAddressSelector").each(function () {
                        $(this)
                            .find("[data-uuid=\"" + uuidToRemove + "\"]")
                            .remove();
                        var optionString = "<option "
                            + "data-uuid=\""
                            + response.editedAddress.UUID
                            + "\""
                            + "data-address-title=\""
                            + response.editedAddress.address.ID
                            + "\""
                            + "data-first-name=\""
                            + response.editedAddress.address.firstName
                            + "\""
                            + "data-last-name=\""
                            + response.editedAddress.address.lastName
                            + "\""
                            + "data-address1=\""
                            + response.editedAddress.address.address1
                            + "\""
                            + "data-address2=\""
                            + response.editedAddress.address.address2
                            + "\""
                            + "data-city=\""
                            + response.editedAddress.address.city
                            + "\""
                            + "data-country-code=\""
                            + response.editedAddress.address.countryCode.value
                            + "\""
                            + "data-postal-code=\""
                            + response.editedAddress.address.postalCode
                            + "\""
                            + "data-phone=\""
                            + response.editedAddress.address.phone
                            + "\""
                            + "data-state-code=\""
                            + response.editedAddress.address.stateCode
                            + "\""
                            + "value=\""
                            + response.editedAddress.address.ID
                            + "\""
                            + ">"
                            + response.editedAddressLabel
                            + "</option>";
                        $(this).append(optionString);
                    });
                    $form
                        .find(".grAddressSelector")
                        .val(response.editedAddress.address.ID);
                }
                $form.parents(".card").removeClass("add-post-event-address");
                $form.parents(".card").removeClass("add-co-registrant");
                $form.parents(".card").find(".event-add-button").remove();
                $.spinner().stop();
            },
            error: function (error) {
                if (error.responseJSON.redirectUrl) {
                    window.location.href = error.responseJSON.redirectUrl;
                }

                $.spinner().stop();
            }
        });
    });
};
