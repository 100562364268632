/**
 *  Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

"use strict";

/**
 * Sanitize the input string
 * @param {string} input - The input string
 * @returns {string} - The sanitized string
 */
module.exports = function (input) {
    if (input && typeof input === "string") {
        return input.replace(/[&<>"'/]/g, "");
    }
    return input;
};
