/**
 *  Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */
"use strict";

var formValidation = require("./egFormValidation");
var sanitize = require("./egInputSanitization");
var braintreeCreditCardModel = require("../../../../../../int_braintree/cartridge/client/default/js/braintree/creditcard/braintreesdk/braintreeCreditCardModel");


module.exports = function () {
    var $body = $("body");

    $("#GiftCertificateForm").submit(function (e) {
        e.preventDefault();
    });

    /**
     * @description Handles the visibility of the V12 tab based on the value of the "showGiftCardGroup" input.
     */
    function handleV12TabVisibility() {
        var $showGiftCardGroup = $("input[name='showGiftCardGroup']");
        var v12Tab = $(".js-nav-item-v12");
        if (v12Tab.length) {
            if ($showGiftCardGroup.val()) {
                v12Tab.fadeOut(500);
                var $paymentInformation = $(".payment-information");
                // If selected payment instrument was V12 one, select credit-card after disabling V12
                if ($paymentInformation.attr("data-payment-method-id").match(new RegExp(/^V12.*$/))) {
                    $("li.nav-item[data-method-id='CREDIT_CARD'] > a").click();
                }
            } else {
                v12Tab.fadeIn(500);
            }
        }
    }

    /**
     * Handles the visibility of the Klarna tab based on the validity of the Klarna payment method.
     * @param {boolean} klarnaValid - Whether the Klarna payment method is valid or not.
     */
    function handleKlarnaTabVisibility(klarnaValid) {
        var klarnaMainContainer = $(".klarna-main-container");
        if (klarnaMainContainer.length) {
            if (!klarnaValid) {
                klarnaMainContainer.fadeOut(500);
                var $paymentInformation = $(".payment-information");
                // If selected payment instrument was klarna one, select credit-card after disabling klarna
                if ($paymentInformation.attr("data-payment-method-id").match(new RegExp(/^klarna.*$/))) {
                    $("li.nav-item[data-method-id='CREDIT_CARD'] > a").click();
                }
            } else {
                klarnaMainContainer.fadeIn(500);
            }
        }
    }

    /**
     * @description Handles the visibility of the gift certificate form tab based on the state of the checkbox.
     * @param {Object} checkbox - The checkbox element that triggers the visibility change.
     */
    function handleGiftCertificateFormTabVisibility(checkbox) {
        let giftCertifcateTab = $("#GiftCertificateForm-Tab");
        if (checkbox.is(":checked")) {
            giftCertifcateTab.fadeIn(500);
        } else {
            giftCertifcateTab.fadeOut(500);
        }
    }

    /**
     * @description hide/show credit credit card section if gift certificate is applied
     */
    function hideCardCheckout() {
        var $creditCardForm = $(".credit-card-form-cart");
        var $userPayment = $(".user-payment-instruments");
        var $cardPlaceOrderButton = $(".submit-payment");
        var $giftCardPlaceOrderButton = $(".submit-payment-gift-cert-only");
        var $addAnotherGiftCardButton = $(".add-another-gift-card");
        var $giftCertificateInputGroup = $(".gift-certificate-input-group");

        if ($(".success.giftcert-pi").length > 0) {
            var addedGiftCertificate = $(".success.giftcert-pi")
                .last()
                .data("gcpiTotal");
            var totalCartPrice = $(".success.giftcert-pi").data("totalPrice");

            if (addedGiftCertificate === totalCartPrice) {
                $creditCardForm.addClass("d-none");
                $userPayment.addClass("d-none");
                $giftCardPlaceOrderButton.show();
                $cardPlaceOrderButton.hide();
                $addAnotherGiftCardButton.addClass("d-none");
                $giftCertificateInputGroup.addClass("d-none");
            } else {
                $creditCardForm.removeClass("d-none");
                $userPayment.removeClass("d-none");
                $giftCardPlaceOrderButton.hide();
                $cardPlaceOrderButton.show();
                $addAnotherGiftCardButton.removeClass("d-none");
                $giftCertificateInputGroup.removeClass("d-none");
            }
        } else {
            $creditCardForm.removeClass("d-none");
            $userPayment.removeClass("d-none");
            $giftCardPlaceOrderButton.hide();
            $cardPlaceOrderButton.show();
            $giftCertificateInputGroup.removeClass("d-none");
        }
    }

    /**
     * @description hide/show gift certificate section on checkout
     * @param {string} amountOfGCs - amount
     */
    function giftCertificateCheckout(amountOfGCs) {
        var $giftCertCode = $(".gift-certificate-checkout");
        var $giftCertMax = $(".gift-certificate-maximum");

        if (amountOfGCs && $(".success.giftcert-pi").length === amountOfGCs) {
            $giftCertCode.addClass("d-none");
            $giftCertMax.removeClass("d-none");
        } else {
            $giftCertCode.removeClass("d-none");
            $giftCertMax.addClass("d-none");
        }
    }

    /**
     * display the notification after the customer has pressed the "Add to Cart" button for gift certificates
     * @param {string} response - ajax response from clicking the add to cart button
     */
    function showNotificationGiftCertificate(response) {
        if ($(".add-to-cart-messages").length === 0) {
            $("body").append("<div class=\"add-to-cart-messages\"></div>");
        }

        $(".add-to-cart-messages").append(
            "<div class=\"alert-success " +
            " add-to-basket-alert text-center\" role=\"alert\">" +
            response.message +
            "</div>"
        );

        setTimeout(function () {
            $(".add-to-basket-alert").remove();
        }, 5000);
    }

    /**
     * @description This function validates the gift certificate purchase form on the client side.
     * @returns {boolean} true if the form is valid, false otherwise
     */
    function validateGiftCertPurchaseFormOnClientSide() {
        var recipient = $("#recipient");
        var recipientEmail = $("#recipientEmail");
        var amount = $("#amount");
        var valid = true;

        if (!recipient.val() || !recipientEmail.val() || !amount.val()) {
            valid = false;
        }

        return valid;
    }

    /**
     * Displays a message based on the data received from the server.
     * @returns {void}
     */
    function updateWishListUI(RemoveGiftCertificateURL) {
        var $addBtn = $(".add-giftcert-to-wish-list");
        var $removeBtn = $(".remove-giftcert-from-wish-list");
        $.spinner().stop();
        var oldWishlishlLength = $(".wishlist-quantity").html();
        oldWishlishlLength = parseInt(oldWishlishlLength, 10);

        if ($addBtn.hasClass("d-none")) {
            $removeBtn.addClass("d-none");
            $addBtn.removeClass("d-none");
            $(".wishlist-quantity").html(oldWishlishlLength - 1);
        } else {
            $addBtn.addClass("d-none");
            $removeBtn.removeClass("d-none");
            $(".wishlist-quantity").html(oldWishlishlLength + 1);
            // Update Remove GiftCert URL
            if (RemoveGiftCertificateURL) {
                var $RemoveGiftCertificateURL = $("input[name='RemoveGiftCertificateURL']");
                $RemoveGiftCertificateURL.val(RemoveGiftCertificateURL);
            }
        }
    }

    $body.on("click", ".giftcert-purchase-btn", function (e) {
        $("body").trigger("effect:Ripple", {
            button: $(this),
            event: e
        });

        if (!validateGiftCertPurchaseFormOnClientSide()) {
            return;
        }

        var $form = $(this).closest("form");
        var url = $form.attr("action");
        $("#giftcertificate-generalerror").type = "hidden";
        $form.spinner().start();
        $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            cache: false,
            data: $form.serialize()
        })
            .done(function (data) {
                $form.spinner().stop();
                if (!data.success) {
                    if (data.GeneralError) {
                        $("#giftcertificate-generalerror").type = "text";
                        $("#giftcertificate-generalerror").value = data.GeneralError;
                    } else {
                        formValidation($form, data);
                    }
                } else {
                    if (data.showBuyseparatelyBanner) {
                        var $buyseparatelybanner = $(".buyseparately-banner");
                        if ($buyseparatelybanner.css("display") === "none" && $buyseparatelybanner.find("div.content-asset").length !== 0) {
                            $buyseparatelybanner.slideDown("slow", function () {
                                $buyseparatelybanner.delay(5000).slideUp("slow");
                            });
                        }
                    }

                    if (!$(".giftcert-purchase-btn").data("update")) {
                        $form.each(function () {
                            this.reset();
                        });

                        $(".js-preview-value").html($("#amount").val());
                        $(".js-preview-from").html($("#from").val());
                        $(".js-preview-to").html($("#recipient").val());
                        $(".js-preview-message").html($("#message").val());
                    } else {
                        window.location.href = $(".giftcert-purchase-btn").data(
                            "cartpage"
                        );
                    }
                    $(".minicart").trigger("count:update", data);
                    $("body").trigger("cart:update");
                    var minicartTime = $(".minicart-total").attr("data-minicartshowtime");
                    if (minicartTime) {
                        $(".minicart").trigger("open");
                        setTimeout(function () {
                            $(".minicart .popover").removeClass("show");
                        }, minicartTime);
                    }
                    showNotificationGiftCertificate(data);
                    updateRemainingCharsForMessageInput();
                }
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $form.spinner().stop();
            });
    });

    $("#GiftCertificateBalanceForm").submit(function (e) {
        e.preventDefault();
    });

    /**
     * egPayCheck
     */
    function egPayCheck() {
        var addedGiftCertificate = $(".success.giftcert-pi")
            .last()
            .data("gcpiTotal");
        var totalCartPrice = $(".success.giftcert-pi").data("totalPrice");
        var cvvCode = $(
            ".saved-payment-instrument." +
            "selected-payment .saved-payment-security-code"
        );
        if (addedGiftCertificate && (addedGiftCertificate === totalCartPrice)) {
            cvvCode.val("  ");
        } else {
            cvvCode.val("");
        }
    }
    egPayCheck();

    $("#add-giftcert").on("click", function () {
        var url = $(this).data("url");
        var $invalidFeedback = $(".invalid-feedback-checkout");

        $.ajax({
            url: url,
            type: "GET",
            cache: false
        })
            .done(function (data) {
                //braintreeCreditCardModel.updateOrderData();
                $invalidFeedback.empty();
                $(".invalid-feedback").empty();
                $(".gift-cert-balance-value").empty();
                if (!data.status) {
                    $("#giftcerts-applied").html(data);
                    egPayCheck();
                    hideCardCheckout();
                    giftCertificateCheckout(data.amountOfGCs);
                    $(".gift-certificate-input-group").addClass("d-none");
                    $(".gift-card-balance").addClass("d-none");
                } else {
                    $invalidFeedback.html(data.message);
                    $(".gift-card-balance").addClass("d-none");
                }
                handleV12TabVisibility();
                $("body").trigger("checkout:updateOrderData");
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                handleV12TabVisibility();
                $("body").trigger("checkout:updateOrderData");
            });
    });

    $("#giftcerts-applied").on("click", ".remove-checkout-gc", function (e) {
        e.preventDefault();
        var url = $(this).attr("href");
        url += $(this).attr("id").split("-")[1];
        $.ajax({
            url: url,
            type: "GET",
            cache: false
        })
            .done(function (data) {
                if (!data.status) {
                    $("#giftcerts-applied").html(data);
                    egPayCheck();
                    hideCardCheckout();
                    giftCertificateCheckout();
                    $("#giftCertCode").removeClass("is-invalid");
                    $(".invalid-feedback-checkout").empty();
                }
                handleV12TabVisibility();
                $("body").trigger("checkout:updateOrderData");
                // else handle errors
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                handleV12TabVisibility();
                $("body").trigger("checkout:updateOrderData");
            });
    });

    /**
     * @function
     * @description Validate the check balance form on the client side
     * @return {boolean} - True if the form is valid, false otherwise
     */
    function validateCheckBalanceFormOnClientSide($invalidFeedback) {
        var giftcertID = $("#giftCertID");
        // Alphanumeric regular expression pattern
        const alphaNumericPattern = /^[a-zA-Z0-9_.-]*$/;
        if (!giftcertID.val()) {
            $invalidFeedback.html(window.egift_resources.fieldisrequired);
            return false;
        } else if (!alphaNumericPattern.test(giftcertID.val())) {
            $invalidFeedback.html(window.egift_resources.notfound);
            return false;
        }
        return true;
    }

    var giftCardArray = [];

    $body.on("click", ".gc-check-balance-button", function () {
        var $invalidFeedback = $("#GiftCertificateBalanceForm > .form-group > .invalid-feedback");
        var checkBalanceContainer = $(".check-balance-container");

        $invalidFeedback.empty();
        checkBalanceContainer.empty();
        if (!validateCheckBalanceFormOnClientSide($invalidFeedback)) {
            return;
        }

        var $form = $(this).closest("form");
        var url = $form.attr("action");
        $form.spinner().start();
        $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            cache: false,
            data: $form.serialize()
        })
            .done(function (data) {
                $form.spinner().stop();
                if (!data.success) {
                    formValidation($form, data);
                    let errorMsg = data.ErrorMsg || data.fields.dwfrm_giftcert_balance_giftCertID;
                    if (errorMsg) {
                        $invalidFeedback.html(errorMsg);
                    }
                } else {
                    var contentHtml = "";

                    var isDuplicate = false;
                    giftCardArray.forEach(element => {
                        if (element.giftcode === data.maskedGiftCertCode) {
                            isDuplicate = true;
                        }
                    });

                    if (!isDuplicate) {
                        giftCardArray.push({
                            img: data.imgSmallPNG,
                            amount: data.value,
                            giftcode: data.maskedGiftCertCode,
                            expDate: data.expDate,
                            isExpired: data.isExpired
                        });
                    }

                    contentHtml += `
                            <div class="row gift-card-tile-balance">
                                <span>
                                    ${window.egift_resources.balance} <b>${(data.isExpired ? window.egift_resources.notavailable : data.value)}</b></span>
                                </span>
                            </div>`;

                    checkBalanceContainer.html(contentHtml);
                }
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $form.spinner().stop();
            });
    });

    $body.on("click", ".add-giftcert-to-wish-list", function (e) {
        var url = $(this).data("url");
        $.spinner().start();
        $.ajax({
            url: url,
            type: "POST",
            cache: false
        })
            .done(function (response) {
                $.spinner().stop();
                updateWishListUI(response.RemoveGiftCertificateURL);
                $("body").trigger("effect:Ripple", {
                    button: $("button.remove-giftcert-from-wish-list"),
                    event: e
                });
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            });
    });

    $body.on("click", ".remove-giftcert-from-wish-list", function (e) {
        var url = $("input[name='RemoveGiftCertificateURL']").val();
        $.spinner().start();
        $.ajax({
            url: url,
            type: "GET",
            cache: false
        })
            .done(function () {
                $.spinner().stop();
                updateWishListUI();
                $("body").trigger("effect:Ripple", {
                    button: $("button.add-giftcert-to-wish-list"),
                    event: e
                });
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            });
    });

    $body.on("click", ".gc-check-balance-button-billing", function () {
        var $invalidFeedback = $(".invalid-feedback-checkout");
        var $giftCardBalance = $(".gift-card-balance");

        $giftCardBalance.addClass("d-none");
        $invalidFeedback.empty();
        if (!validateCheckBalanceFormOnClientSide($invalidFeedback)) {
            return;
        }
        var url = $(this).data("url");
        const $form = $(this).closest("form");
        $.spinner().start();
        $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            cache: false,
            data: $form.serialize()
        })
            .done(function (data) {
                var $giftCertBalanceValue = $giftCardBalance.find(".gift-cert-balance-value");
                $giftCertBalanceValue.empty();
                $.spinner().stop();
                if (!data.success) {
                    $giftCardBalance.addClass("d-none");
                    formValidation($form, data);
                    let errorMsg = data.ErrorMsg || data.fields.dwfrm_giftcert_balance_giftCertID;
                    if (errorMsg) {
                        $invalidFeedback.html(errorMsg);
                        $giftCardBalance.addClass("d-none");
                    }
                } else {
                    $giftCardBalance.removeClass("d-none");
                    $giftCertBalanceValue.html("" + data.value);
                    var $RedeemGiftCertificateJsonURL = $("input[name='RedeemGiftCertificateJsonURL'").val();
                    var $applyButton = $giftCardBalance.find("#add-giftcert");
                    $applyButton.data("url", $RedeemGiftCertificateJsonURL + data.maskedGiftCertCode);
                }
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            });
    });

    $body.on("click", ".checkout-select-egiftcard", function () {
        var id = $(this).data("id");
        var $invalidFeedback = $(".invalid-feedback-checkout");
        var url = $(this).data("url");
        url += id;
        $.spinner().start();
        $.ajax({
            url: url,
            type: "GET",
            cache: false
        })
            .done(function (data) {
                $.spinner().stop();
                $(".gift-card-balance-message").addClass("d-none");
                $(".invalid-feedback").empty();
                $(".gift-cert-balance-value").empty();
                if (!data.status) {
                    $("#giftcerts-applied").html(data);
                    egPayCheck();
                    hideCardCheckout();
                    giftCertificateCheckout();
                } else {
                    $invalidFeedback.html(data.message);
                }
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            });
    });

    /**
     * @description hide/show gift certificate input if have a gift certificate in cart
     */
    function hideGiftCertificateInput() {
        if ($(".gift-certificate__tile").length > 0) {
            $("#GiftCertificateForm").addClass("d-none");
        }
    }

    $body.on("click", ".add-giftcert-to-giftregistry", function () {
        var url = $(this).data("url");
        $.spinner().start();
        $.ajax({
            url: url,
            type: "POST",
            cache: false
        })
            .done(function (data) {
                var $clearBtn = $(".clear-list-btn");

                if ($clearBtn.hasClass("d-none")) {
                    $clearBtn.removeClass("d-none");
                }

                $(".add-items-to-gift-registry").remove();
                $(".add-giftcert-to-giftregistry").addClass("d-none");
                $(".giftregistry-itemlist").html(data);
                $.spinner().stop();
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            });
    });
    /**
     * @description hide/show gift certificate account
     */
    function giftCertificateAccount() {
        var $showMoreGiftCert = $(".show-more-giftcert");
        var $showMoreGiftCertBtn = $(".show-more-giftcert-btn");
        var addCard = $("#addGiftCardByNumber");

        if ($showMoreGiftCert.length > 0) {
            $showMoreGiftCert.addClass("d-none");
            $showMoreGiftCertBtn.removeClass("d-none");
        }

        $showMoreGiftCertBtn.click(function () {
            $showMoreGiftCert.toggle();
            if ($(this).text() === "View all") {
                $(this).text("View all");
            } else {
                $(this).text("View less");
            }
        });

        $("#addGiftCardByNumber").on("click", function () {
            var inputCardNumber = $("#input-add-by-giftcard-number").val();
            var url = addCard.data("url");
            var result = $(".add-giftcard-by-number__result");

            if (!inputCardNumber) {
                result.html(window.egift_resources.giftcardnumberemptyerror);
                result.css("display", "block");
                return;
            }

            $.ajax({
                url: url,
                type: "GET",
                cache: false,
                data: {
                    giftCertID: inputCardNumber
                }
            }).done(function (data) {
                result.show();
                if (data.success === true) {
                    result.html(window.egift_resources.giftcardadded);
                    result.css("color", "#4bb543");
                    setTimeout(window.location.reload(), 300);
                } else {
                    result.html(data.message);
                }
            });
        });
    }
    /**
     * @description show cards carousel
     */
    function cardsCarousel() {
        $(".gift-certificate-cart__carousel").slick({
            dots: true,
            centerMode: true,
            slidesToShow: 3,
            infinite: false,
            variableWidth: true
        });

        // set correct slide when edit card
        var slideIndexToShow = parseInt(
            $(".js-show-current-slide").data("slickIndex"),
            10
        );
        $(".gift-certificate-cart__carousel").slick(
            "slickGoTo",
            slideIndexToShow,
            true
        );
    }
    /**
     * @description apply cart to input
     */
    function applyCartToInput() {
        var currentImgSrc = $(
            ".gift-certificate-cart__carousel .slick-current img"
        ).attr("src");

        // set preview-card img on load
        $(".js-preview-card img").attr("src", currentImgSrc);

        $(".gift-certificate-cart__carousel").on("afterChange", function () {
            currentImgSrc = $(
                ".gift-certificate-cart__carousel .slick-current img"
            ).attr("src");

            // get string path and cut size with extention
            var currentSrc = currentImgSrc.split("-").pop();
            var stringSrc = currentImgSrc.replace("-" + currentSrc, "");

            // add sizes and extentions
            var bigPng = stringSrc + "-big.png";
            var smallPng = stringSrc + "-small.png";
            var bigSvg = stringSrc + "-big.svg";
            var smallSvg = stringSrc + "-small.svg";

            // apply path to input's value
            $("#imgSmallSVG").val(smallSvg);
            $("#imgSmallPNG").val(smallPng);
            $("#imgBigSVG").val(bigSvg);
            $("#imgBigPNG").val(bigPng);

            // change preview-card img
            $(".js-preview-card img").attr("src", currentImgSrc);
        });
    }

    /**
     * Updates the remaining characters count for a message input field.
     *
     * @function
     * @name updateRemainingCharsForMessageInput
     * @memberof global
     *
     * @returns {void}
     */
    function updateRemainingCharsForMessageInput(inputMessage) {
        let remainingChars = $("#messageRemainingChars");
        let charLength = 0;
        if (inputMessage && inputMessage.length) {
            charLength = inputMessage.val().length;
        }
        let limit = 150;
        remainingChars.text(limit - charLength);
    }

    /**
     * @description preset amount buttons
     */
    function presetAmountButtons() {
        // set preselected value if present
        if ($("#amount").val()) {
            $(".js-preset-amount-value").html(
                ($(".preset-amount-btns__btn").data("currency") ?
                    $(".preset-amount-btns__btn").data("currency") :
                    "") + $("#amount").val()
            );
        }

        // Listen preset buttons
        $(".preset-amount-btns__btn").on("click", function () {
            // set default style to all buttons
            $(".preset-amount-btns__btn").each((i, item) => {
                $(item).css({
                    "background-color": "#fff",
                    color: "#333"
                });
            });
            // set color style to clicked button
            $(this).css({
                "background-color": "#00a1e0",
                color: "#fff"
            });

            // assign value to "Value" and form input
            $(".js-preset-amount-value").html(
                ($(this).data("currency") ? $(this).data("currency") : "") +
                $(this).data("value")
            );
            $("#amount").val($(this).data("value"));

            // Change preview section value
            $(".js-preview-value").html(
                ($(this).data("currency") ? $(this).data("currency") : "") +
                $(this).data("value")
            );
        });

        $(".js-another-value-btn").on("click", function () {
            $("#amount").focus();
        });

        // set preview amount value on load
        if ($("#amount").val()) {
            $(".js-preview-value").html(
                ($(".js-preset-amount-btn").data("currency") ?
                    $(".js-preset-amount-btn").data("currency") :
                    "") + $("#amount").val()
            );
        }

        /* Don't allow any input to amount except integer */
        $("#amount").on("keydown", function (event) {
            // Allow: backspace, delete, tab, escape, enter, Ctrl+C, Ctrl+V
            if ($.inArray(event.key, ["Backspace", "Delete", "Tab", "Escape", "Enter", "c", "v", "x"]) !== -1 ||
                // Allow: Ctrl+A
                (event.key == "a" && event.ctrlKey === true) ||
                // Allow: home, end, left, right
                (event.key == "Home" || event.key == "End" || event.key == "ArrowLeft" || event.key == "ArrowRight")) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if ((event.shiftKey || (event.key < "0" || event.key > "9")) && (event.key < "NumPad0" || event.key > "NumPad9")) {
                event.preventDefault();
            }
        });


        // Listen amount input keydown
        $("#amount").on("keyup change", function () {

            /* If there are any non-digits in the input; remove them */
            let regEx = /[^0-9]/g;
            if (regEx.test($(this).val())) {
                $(this).val($(this).val().replace(regEx, ""));
            }

            $(".preset-amount-btns__btn").each((i, item) => {
                $(item).css({
                    "background-color": "#fff",
                    color: "#333"
                });
            });
            $(".js-another-value-btn").css({
                "background-color": "#00a1e0",
                color: "#fff"
            });
            $(".js-preset-amount-value").html(
                ($(".js-preset-amount-btn").data("currency") ?
                    $(".js-preset-amount-btn").data("currency") :
                    "") + $(this).val()
            );

            // Delete currency symbol if no value
            if ($(this).val() === "") {
                setTimeout(() => {
                    $(".js-preview-value").html("");
                    $(".js-preset-amount-value, .js-preview-value").html("");
                }, 0);
            }

            // Change preview section value
            $(".js-preview-value").html(
                ($(".js-preset-amount-btn").data("currency") ?
                    $(".js-preset-amount-btn").data("currency") :
                    "") + $(this).val()
            );
        });

        var prevValue;
        $("#message").on("input", function () {
            var $this = $(this);
            var pattern = /^[a-zA-Z0-9\s]*$/;
            var hasError = !$this.val().match(pattern);
            if (hasError) {
                $this.val(prevValue);
            } else {
                updateRemainingCharsForMessageInput($(this));
                prevValue = $this.val();
            }
        });
    }
    /**
     * @description preview the gift card
     */
    function giftCardPreview() {
        var name = $("#from");
        var message = $("#message");
        var to = $("#recipient");

        // set preview data on load
        $(".js-preview-from").text(name.val());
        $(".js-preview-message").text(message.val());
        $(".js-preview-to").text(to.val());

        $(document).on("keyup", "#from, #message, #recipient", function () {
            $(".js-preview-from").text(sanitize(name.val()));
            $(".js-preview-message").text(sanitize(message.val()));
            $(".js-preview-to").text(sanitize(to.val()));
        });
    }

    giftCertificateCheckout();
    hideGiftCertificateInput();
    giftCertificateAccount();
    if ($(".gift-certificate-cart__carousel").length > 0) {
        cardsCarousel();
    }
    applyCartToInput();
    presetAmountButtons();
    giftCardPreview();

    var $showGiftCardGroup = $("input[name='showGiftCardGroup']");
    if ($showGiftCardGroup.val()) {
        $("#show-giftcertifcate-form").prop("checked", "true");
        $(".gift-certificate-input-group").addClass("d-none");
        handleV12TabVisibility();
    }
    handleGiftCertificateFormTabVisibility($("#show-giftcertifcate-form"));
    $("#show-giftcertifcate-form").change(function () {
        handleGiftCertificateFormTabVisibility($(this));
    });

    /* Check if there are already redeemed Gift Card, show Gift Card section */

    $(document).on("click", ".add-another-gift-card", function () {
        $("#giftCertID").val("");
        $(".gift-certificate-input-group").removeClass("d-none");
    });

    $(document).ready(function () {
        updateRemainingCharsForMessageInput($("#message"));
        var $giftCertificateInputGroup = $(".gift-certificate-input-group");
        if ($giftCertificateInputGroup && $giftCertificateInputGroup.length) {
            var giftCerdInput = $giftCertificateInputGroup.find("#giftCertID");
            giftCerdInput.val("");
        }
    });

    $(document).on("input", "#giftCertID", function () {
        var $input = $(this);
        $input.val($input.val().toUpperCase());
        var $button = $(".gc-check-balance-button");

        if ($(".gc-check-balance-button-billing").length > 0) {
            $button = $(".gc-check-balance-button-billing");
        }

        // Check if the input has a value
        if ($input.val().trim() !== "") {
            // If it has a value, change the button color to black and set font-weight to bold
            $button.css({
                "color": "black",
                "font-weight": "bold"
            });
        } else {
            // If it doesn't have a value, reset the button color and font-weight
            $button.css({
                "color": "",
                "font-weight": ""
            });
        }
    });

    $body.on("checkout:handleKlarnaTabVisibility", function (e, klarnaValid) {
        handleKlarnaTabVisibility(klarnaValid);
    });
};
