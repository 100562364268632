/**
 *  Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

"use strict";

/* global */
document.addEventListener("DOMContentLoaded", function () {
    (function ($) {
        var eGiftCard = require("./eGiftCard/egiftcard");
        var cart = require("./eGiftCard/egCart");
        var wishlist = require("./eGiftCard/egWishlist");
        var registry = require("./eGiftCard/egRegistry");

        $(document).ready(function () {
            eGiftCard();
            cart();
            wishlist();
            registry();
        });
    }(window.jQuery));
});
