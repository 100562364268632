/**
 *  Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

"use strict";
/* global $ */

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearFormErrors(form) {
    $(form).find(".form-control.is-invalid").removeClass("is-invalid");
}

module.exports = function (formElement, payload) {
    // clear form validation first
    clearFormErrors(formElement);
    $(".alert", formElement).remove();

    if (typeof payload === "object" && payload.fields) {
        Object.keys(payload.fields).forEach(function (key) {
            if (payload.fields[key]) {
                var feedbackElement = $(formElement).find("[name=\"" + key + "\"]")
                    .parent()
                    .children(".invalid-feedback");

                if (feedbackElement.length > 0) {
                    if (Array.isArray(payload[key])) {
                        feedbackElement.html(payload.fields[key].join("<br/>"));
                    } else {
                        feedbackElement.html(payload.fields[key]);
                    }
                    feedbackElement.siblings(".form-control").addClass("is-invalid");
                }
            }
        });
    }
    if (payload && payload.error) {
        var form = $(formElement).prop("tagName") === "FORM"
            ? $(formElement)
            : $(formElement).parents("form");

        form.prepend("<div class=\"alert alert-danger\" role=\"alert\">"
            + payload.error.join("<br/>") + "</div>");
    }
};
