/**
 *  Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

"use strict";

/**
 * @param {Object} $elementAppendTo - The element to append error html to
 * @param {string} msg - The error message
 * display error message if remove gift registry failed
 */
function displayErrorMessage($elementAppendTo, msg) {
    if ($(".remove-gift-registry-error-messages").length === 0) {
        $elementAppendTo.append(
            "<div class=\"remove-gift-registry-error-messages \"></div>"
        );
    }
    $(".remove-gift-registry-error-messages").append(
        "<div class=\"remove-gift-registry-error-alert text-center alert-danger\">"
        + msg + "</div>"
    );

    setTimeout(function () {
        $(".remove-gift-registry-error-messages").remove();
    }, 3000);
}

/**
 * Display error message if remove gift registry failed
 * @param {number} pageNumber pageNumer
 * @param {number} spinner spinner
 * @param {number} focusElementSelector focusElementSelector
 */
function renderNewPageOfItems(pageNumber, spinner, focusElementSelector) {
    var publicView = $(".wishlistItemCardsData").data("public-view");
    var listUUID = $(".wishlistItemCardsData").data("uuid");
    var url = $(".wishlistItemCardsData").data("href");
    if (spinner) {
        $.spinner().start();
    }
    var scrollPosition = document.documentElement.scrollTop;
    var newPageNumber = pageNumber + 1;
    $.ajax({
        url: url,
        method: "get",
        data: {
            pageNumber: newPageNumber, // NOSONAR
            publicView: publicView,
            id: listUUID
        }
    })
        .done(function (data) {
            $(".wishlistItemCards").empty();
            $("body .wishlistItemCards").append(data);

            if (focusElementSelector) {
                $(focusElementSelector).focus();
            } else {
                document.documentElement.scrollTop = scrollPosition;
            }
        })
        .fail(function () {
            $(".more-wl-items").remove();
        });
    $.spinner().stop();
}

module.exports = function () {
    // removeFromWishlist
    $("body").off("click", ".more-wl-results");
    $("body").on("click", ".more-wl-results", function () {
        var firstName = $(this).data("search-fname");
        var lastName = $(this).data("search-lname");
        var pageNumber = $(this).data("page-number");
        var uuids = [];
        $(".wl-hit").each(function () {
            uuids.push($(this).find("a").data("id"));
        });
        var url = $(this).data("url");
        $.spinner().start();
        pageNumber += 1;
        $.ajax({
            url: url,
            method: "get",
            data: {
                firstName: firstName,
                lastName: lastName,
                uuids: JSON.stringify(uuids),
                pageNumber: pageNumber // NOSONAR
            },
            success: function (data) {
                if (data.results.changedList) {
                    $(".wl-hits .wl-hit").remove();
                }
                $("#result-count").html(data.results.totalString);
                data.results.hits.forEach(function (hit) {
                    var divString = "<div class=\"row wl-hit\">"
                    + "<div class=\"text-left col-6\">"
                    + hit.firstName + " " + hit.lastName
                    + "</div>"
                    + "<div class=\"text-right col-6\">"
                    + "<a href=\"" + hit.url + "\" title=\""
                    + hit.urlTitle + "\" data-id=\""
                    + hit.id + "\">" + hit.urlText
                    + "</a></div>"
                    + "<div class=\"text-left col-12\">"
                    + hit.email + "</div></div>";
                    $(".wl-hits").append(divString);
                });
                if (data.results.showMore) {
                    $(".find-another-wl .more-wl-results").data(
                        "page-number",
                        data.results.pageNumber
                    );
                } else {
                    $(".find-another-wl .more-wl-results").remove();
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};
